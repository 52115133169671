import { body, html, renderTemplate, strip, transformers, noteOrds, templateIndexes, } from './cardHtml';
import { toastError, toastFatal, toastImpossible, toastInfo, toastWarn, } from './toasts';
export const defaultRenderContainer = (args) => ({
    ...args,
    transformers,
    body,
    renderTemplate,
    html,
    strip,
    noteOrds,
    templateIndexes,
    toastError,
    toastFatal,
    toastImpossible,
    toastInfo,
    toastWarn,
});
export const noteOrdsRenderContainer = (args) => ({
    ...defaultRenderContainer(args),
    strip: (x) => x,
});
